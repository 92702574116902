import React from "react";
import { graphql, Link } from "gatsby";
import { useState } from "react";

import Layout from "../components/layout-main";
import { trackButtonCta } from "../components/analytics";
import { scrollToElm } from "../components/dom";
import {
  BereavementContact,
  ContactForm,
  FormTypes,
} from "../components/form-contact";
import CalendlySection from "../components/form-calendly";
import Testimonials from "../components/testimonials";
import { Pricing } from "../constants/pricing";
import { CalendlyEventTypes } from "../components/form-calendly";

import { keyframes } from "@emotion/react";

import Img from "gatsby-image";

/** @jsx jsx */
import {
  jsx,
  Grid,
  Flex,
  Button,
  Styled,
  NavLink,
  Slider,
  Label,
} from "theme-ui";
import { Faqs } from "../components/faqs";

import { FuneralHomeTestimonials } from "../constants/testimonials";
import { PricingTable } from "../components/pricing-table";
import Routes from "../constants/routes";

const titleTextSize = { fontSize: ["40px", "52px"] };

let navLinksOverride = () => {
  return (
    <>
      <NavLink
        tabIndex="0"
        href="#features"
        onClick={(event) => {
          event.preventDefault();
          scrollToElm("features");
        }}
      >
        Features
      </NavLink>
      <NavLink
        tabIndex="0"
        href="#aboutus"
        onClick={(event) => {
          event.preventDefault();
          scrollToElm("aboutus");
        }}
      >
        About
      </NavLink>
      <NavLink
        tabIndex="0"
        href="#howitworks"
        onClick={(event) => {
          event.preventDefault();
          scrollToElm("howitworks");
        }}
      >
        How it Works
      </NavLink>
      <NavLink
        tabIndex="0"
        href="#faqs"
        onClick={(event) => {
          event.preventDefault();
          scrollToElm("faqs");
        }}
      >
        FAQs
      </NavLink>
      <NavLink
        tabIndex="0"
        href="#testimonials"
        onClick={(event) => {
          event.preventDefault();
          scrollToElm("testimonials");
        }}
      >
        Testimonials
      </NavLink>
    </>
  );
};

const FuneralHomes = ({ data, location }) => {
  return (
    <Layout
      location={location}
      pageTitle="Making Funeral Planning Easier for Everyone"
    >
      <section id="pricing">
        <div className="aw-content">
          <h2
            sx={{
              fontFamily: "Georgia",
              marginTop: 0,
              fontWeight: 200,
              ...titleTextSize,
            }}
          >
            Pricing & Plans
          </h2>
          <PricingTable
            footerTitle="Have fewer than 60 cases a year?"
            footerText="We also offer per case pricing for smaller funeral homes. Contact us to get started"
          />
        </div>
      </section>

      <CalendlySection
        pageName="funeral-homes"
        headerOverride="Ready to increase your ARPC? Schedule a free demo with us"
        buttonOverride="Schedule a demo"
        // sectionSx={{ padding: 0 }}
        // contentSx={{ paddingTop: "0 !important" }}
        buttonVariant={"blueButton"}
        calendlyType={CalendlyEventTypes.FUNERAL_HOMES}
      />

      <section id="how-it-works">
        <div className="aw-content">
          <h2 sx={{ fontFamily: "Georgia", fontWeight: 200, ...titleTextSize }}>
            We treat you like you treat your families
          </h2>

          <Grid columns={["1fr", "1fr 1fr"]}>
            <div>
              <Img
                title="always have time for you"
                alt="always have time for you"
                sx={{
                  width: "48px",
                  height: "auto",
                }}
                fluid={data.here247.childImageSharp.fluid}
              />
              <h4>WE ALWAYS HAVE TIME FOR YOU</h4>
              <p sx={{ fontSize: "20px" }}>
                You’re available to your families 24/7 365 days a year. We're
                the same. With 24/7 access to your team by phone, text, email,
                you’ll always be supported.
              </p>
            </div>
            <div>
              <Img
                title="compassionate and judgment-free"
                alt="compassionate and judgment-free"
                sx={{
                  width: "48px",
                  height: "auto",
                }}
                fluid={data.hereSupport.childImageSharp.fluid}
              />
              <h4>PRIVATELY OWNED</h4>
              <p sx={{ fontSize: "20px" }}>
                We’re a small team with a big mission to help the next
                generation of funeral planners. We’re 100% privately owned and
                operated, just like you.
              </p>
            </div>
            <div>
              <Img
                title="accessible"
                alt="accessible"
                sx={{
                  width: "48px",
                  height: "auto",
                }}
                fluid={data.hereAccessible.childImageSharp.fluid}
              />
              <h4>BUILT WITH EXPERIENCE</h4>
              <p sx={{ fontSize: "20px" }}>
                We started our careers working in tech at companies like Google,
                Shopify, and Workday. Speed, ease of use, and security are
                important to us. We take your feedback and build new features at
                record speed.
              </p>
            </div>
            <div>
              <Img
                title="personalized and meaningful"
                alt="personalized and meaningful"
                sx={{
                  width: "48px",
                  height: "auto",
                }}
                fluid={data.herePersonalized.childImageSharp.fluid}
              />
              <h4>YOUR DATA EXACTLY HOW YOU NEED IT</h4>
              <p sx={{ fontSize: "20px" }}>
                You have years of case data that you need when you need it. We
                safely migrate all of your case history exactly how you have it
                so you can always access it.
              </p>
            </div>
          </Grid>
        </div>
      </section>
    </Layout>
  );
};

export default FuneralHomes;

export const pageQuery = graphql`
  query {
    hereAccessible: file(relativePath: { regex: "/trophy.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    herePersonalized: file(relativePath: { regex: "/chart-histogram.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    here247: file(relativePath: { regex: "/phone-call.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    hereSupport: file(relativePath: { regex: "/following.png/" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
