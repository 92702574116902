import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { Grid, Flex } from "theme-ui";
/** @jsx jsx */
import { jsx } from "theme-ui";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
};

export default class Testimonials extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      desktopSmall: {
        breakpoint: { max: 1200, min: 800 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 800, min: 464 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };

    const testStyles = {
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
      paddingLeft: ["20px", "40px"],
      marginLeft: ["25px", "0"],
      textAlign: "center",
    };

    const reviews = this.props.reviews || [
      {
        review: `We contacted Effie and Laura at Afterword to help with our service. The participants spanned countries Thailand, Spain, USA, Canada.
      Laura worked with us personally. She was skilled, patient and responded quickly to everything. She got all our input and put it together, then hosted the sharing circle. The service included Christian,  Bhuddist and secular elements, videos, audio, photos and recorded music.  It all went well, it was a moving and beautiful service.
      Thank you.`,
        author: "Victor J.",
      },
      {
        review: `I want to thank Effie from the bottom of my heart for
        helping us to pull together such a beautiful memorial
        service for my father. With Effie's help, we were able to
        honor him in the best way possible. Effie's calm approach
        and wise guidance resulted in a memorial service which
        allowed people to connect in a deeply meaningful way. I had
        several people tell me it was the best memorial service that
        they had ever attended (which is really saying something
        considering it was shared by a generation that isn't used to
        virtual events!). I wholeheartedly endorse Effie's services`,
        author: "Rosemarie D.",
      },
      {
        review: `The Afterword team were incredible to work with. Responsive,
        compassionate, helpful and thoughtful from the very start.
        They worked seamlessly and professionally with the venue.
        Many of our guests who joined us virtually remarked that
        they felt like they were right there with us in person such
        was their experience. I really can’t thank them enough for
        supporting us through this time.`,
        author: "Amy B.",
      },
      {
        review: `Last minute, eleventh hour, and on New Year's Eve Afterword
        to the rescue. My father's service was on the following
        Tuesday, Afterword worked with me through New Years Day and
        the weekend to ensure my father's service would be flawless
        and flawless it was! I received many comments of the
        quality, clarity, sound, "camera work" of the feed. The
        online viewers felt as if they were in the room,
        participating in the service.`,
        author: "Thomas M.",
      },
      {
        review: `Afterword gave me the best service I could imagine. I would
        give them 6 Stars if I could. They listen carefully and
        respond intelligently and quickly. I think I was possibly
        rather demanding, asking for a lot, pretty much done my own
        way. The response was perfect, and the memorial for my late
        husband was quite satisfying. My recommendation is
        unconditional!`,
        author: "Sheridan B.",
      },
      {
        review: `There aren't enough words to express my gratitude for Effie
        & her team. The companies I came across online that did this
        were all so expensive that I just didn't know what to do.
        The service was everything we wanted and more. The
        testimonies & the time to talk with our loved ones
        afterwards, was so intimate & special that my mother, who
        has dementia, thought we were in a funeral home. She said
        "It's time to go home now". Thank you so much, Afterword,
        from the bottom of our hearts.`,
        author: "Maureen D.",
      },
      {
        review: `I highly recommend Afterword and their services. She helped
        us create a beautiful virtual memorial service with the
        ability to have speakers, friends and family contributing
        from all over the country. Effie was punctual, thorough,
        very responsive and made a virtual memorial service come
        across as warm and caring. She held individual calls with
        some of the speakers who couldn’t make the group call, and
        made everyone feel so welcomed. Effie’s calming demeanor is
        exactly what is needed during such a difficult time. I am
        forever thankful to her and her team as I honored my loved
        one.`,
        author: "Syreeta M.",
      },
      {
        review: `Thanks to Afterword, we were able to give my mom and her husband a truly wonderful memorial tribute. Every person we worked with was kind, thoughtful, attentive and highly competent. It was absolutely better than we had hoped; better than if we had gathered in person. Family and friends from all over the country were able to participate and attend online. I will be grateful to Afterword for the rest of my life for making it possible to honor my Mom in her passing with All of the Love we could possibly offer, regardless of the pandemic... I can’t imagine anyone doing their job better.`,
        author: "Shane H.",
      },
      {
        review: ` My mom lost her fight against COVID along with over 60,000 other
        beautiful souls... After reaching out to Effie on day 1 I knew Afterword could provide
        everything I needed to celebrate Moms beautiful life... they worked
        with me during the planning stages as if my Mom were her mom... incorporating so many moving
        parts, poems, beautiful backgrounds, musicians... There were 79 guests and I felt connected to all our
        family and friends during the celebration, the slide presentation
        was outstanding and a keepsake to treasure forever. Afterword is and
        will forever be a blessing in my life.`,
        author: "Dee C.",
      },
      {
        review: `Kiersten put on an excellent ceremony for us, it was absolutely perfect and there aren't enough words to describe how great it was, she was as helpful as could be with planning it all setting it all up and executing it and helping anyone with any technical difficulties. I would recommend this service to anyone. Thank you again we are 110% satisfied with the outcome thank you!!!!`,
        author: "Charles G.",
      },
      {
        review: `In a time of terrible grief & loss, Effie & Laura helped make this Memorial Celebration a time when we could grieve, tell stories & truly celebrate what was a remarkable life. They made it possible for people from around the country to as far away as Scotland to participate.
        Astounding attention to detail and amazing technical support. I love that not only is there a page on their site for the obituary, visitation and service but also a video that was made before death.      
        Recommend Afterword to anyone who would like to have an in-person and virtual funeral or memorial. Outstanding!`,
        author: "Elizabeth G.",
      },
      {
        review: `There are not enough good things to say about Afterword. From the first time I reached out to them to our last conversation with Zack, they were courteous, polite and patient... With the pandemic and friends and family all over the country and the world, the memorial page allowed them to be a part of the funeral and allowed them to see a slideshow we made for him and share their thoughts and memories with messages and video messages. We highly recommend them`,
        author: "Lewis P.",
      },
    ];
    shuffleArray(reviews);

    return (
      <section className={this.props.sectionClasses} id={this.props.id}>
        <div className="aw-content">
          <h2 sx={{ textAlign: "center" }}>
            {this.props.title || "What families are saying"}
          </h2>

          <p
            sx={{ fontSize: "12px", marginBottom: "8px", textAlign: "center" }}
          >
            <Link
              href={`https://www.google.com/search?ved=1t:65428&hl=en&q=Afterword&ludocid=6984910543917685674&lsig=AB86z5UegKdeFVGPjdSMcp2ZMO8A#lrd=0x808f7f98ee054a9d:0x60ef6321ce181baa,1,,,`}
              target="_blank"
              sx={{ textDecoration: "none" }}
            >
              Rated 5.0 stars
            </Link>{" "}
            & trusted by hundreds of families
          </p>
          <div sx={{ marginBottom: "24px", textAlign: "center" }}>
            <img
              src="https://cdn-icons-png.flaticon.com/64/1828/1828884.png"
              width="24px"
              height="24px"
            />
            <img
              src="https://cdn-icons-png.flaticon.com/64/1828/1828884.png"
              width="24px"
              height="24px"
            />
            <img
              src="https://cdn-icons-png.flaticon.com/64/1828/1828884.png"
              width="24px"
              height="24px"
            />
            <img
              src="https://cdn-icons-png.flaticon.com/64/1828/1828884.png"
              width="24px"
              height="24px"
            />
            <img
              src="https://cdn-icons-png.flaticon.com/64/1828/1828884.png"
              width="24px"
              height="24px"
            />
          </div>

          <div
            sx={{
              // paddingBottom: "30px",
              fontSize: "24px",
              margin: "0 auto",
              "& .carousel-container": {
                position: "static",
              },
              "& .react-multiple-carousel__arrow--left": {
                // left: "-5px",
              },
              "& .react-multiple-carousel__arrow--right": {
                // right: "5px",
              },
            }}
          >
            <Carousel
              swipeable={true}
              draggable={false}
              slidesToSlide={1}
              // showDots={true}
              // renderDotsOutside
              responsive={responsive}
              ssr={false} // means to render carousel on server-side.
              infinite={true}
              autoPlay={this.props.deviceType !== "mobile" ? true : false}
              autoPlaySpeed={6000}
              keyBoardControl={false}
              transitionDuration={500}
              containerClass="carousel-container"
              removeArrowOnDeviceType={[]}
              deviceType={this.props.deviceType}
              dotListClass="custom-dot-list-style"
              minimumTouchDrag={80}
              renderButtonGroupOutside={true}
              itemClass="carousel-item-testimonial"
              ref={(el) => (this.Carousel = el)}
              additionalTransfrom={-40}
            >
              {reviews.map((review, idx) => {
                return (
                  <div key={idx}>
                    <Flex sx={testStyles}>
                      <p>{review.review}</p>
                      {review.author && <p>— {review.author}</p>}
                    </Flex>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section>
    );
  }
}
